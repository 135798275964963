<template>
    <div class="myNakedChat">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">我的裸聊</div>
            <div class="rightBtn"></div>
        </div>
        <div class="listBox">
            <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
                :refreshing="refreshing" :isNoData="isNoData" :isHigehtMax='true'>
                <div class="contenBox">
                    <router-link tag="div" :to="`/nudeChatDetails?cid=${item.id}`" class="cardBox" v-for="(item, index) in nudeChatList" :key="index">
                        <ImgDecypt class="nudeChatImg" :src="item.imageCover[0]"></ImgDecypt>
                        <div class="infoBox">
                            <div class="title">{{item.title}}</div>
                            <div>
                                <div class="infoItem">
                                    <span>妹子信息：</span>
                                    <span>{{item.age ? item.age + '岁 ' : ''}}</span>
                                    <span>{{item.height ? item.height + 'cm ' : ''}}</span>
                                    <span>{{item.weight ? item.weight + 'kg ' : ''}}</span>
                                    <span>{{cupArr[item.cup]}}</span>
                                </div>
                                <div class="infoItem"><span>连线时间：</span>{{item.serviceTime}}</div>
                                <div class="infoDetail">
                                    <div class='infoItem_title'>详情介绍：</div>
                                    <div class="infoItem_content"><span>{{item.detail}}</span></div>
                                </div>
                            </div>
                            <!-- <div class="total">
                                <svg-icon class="total_svg" iconClass="eye"></svg-icon><span>43966</span>
                                <svg-icon class="total_svg" iconClass="lock"></svg-icon><span>{{item.countPurchases | watchCount}}</span>
                            </div> -->
                            <div class="btnBox">
                                <router-link tag="div" class="btn" :to="`/nudeChatReport?id=${item.id}`">举报</router-link>
                            </div>
                        </div>
                    </router-link>

                </div>
            </Pullrefresh>
        </div>
    </div>
</template>

<script>
    import {
        queryMyNudeChat
    } from '@/api/mine/myNudeChat.js';
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false, // loading状态
                finished: false, // 是否还有更多状态
                refreshing: false, // 刷新状态
                isNoData: false, // 是否有数据
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                nudeChatList: [],
                cupArr: ["全部", "C罩杯", "D罩杯", "E罩杯", "F罩杯"], // cup筛选选项
            }
        },
        created() {
            this.queryMyNudeChat();
        },
        methods: {
            onLoad() {
                this.pageNum++;
                this.queryMyNudeChat();
            },
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.nudeChatList = [];
                this.queryMyNudeChat();
            },
            async queryMyNudeChat() {
                let req = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }
                this.loading = true;
                this.isNoData = false;
                let ret = await this.$Api(queryMyNudeChat, req);
                if (ret && ret.code == 200) {
                    let list = ret.data.list ? ret.data.list : [];
                    this.marqueeText = ret.data.marquee;
                    this.nudeChatList = this.nudeChatList.concat(list)
                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        this.loading = false;
                        this.refreshing = false;
                        this.firstLoading = false;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .myNakedChat {
        background: $whiteThree;
        height: 100vh;

        .listBox {
            height: calc(100vh - 44px);
        }

        .navbar {
            // position: fixed;
            // top: 0;
            background: $vermillion;
            width: 100%;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .navbar_title {
                font-weight: 600;
            }

            .rightBtn {
                width: 25px;
            }
        }

        .contenBox {
            padding: 20px 10px 0;

            .cardBox {
                height: 190px;
                padding: 6px;
                border-radius: 4px;
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
                display: flex;
                justify-content: space-between;

                .nudeChatImg {
                    width: 129px;
                    height: 100%;
                    border-radius: 6px;
                }

                .infoBox {
                    flex: 2;
                    margin-left: 23px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 9px;
                        height: 24px;
                        line-height: 24px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .infoItem {
                        width: 178px;
                        font-size: 10px;
                        margin-bottom: 10px;
                        font-weight: 400;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        height: 14px;
                        line-height: 14px;
                        color: $greyishBrown;

                        span {
                            font-weight: 600;
                            color: $black;
                        }

                    }

                    .infoDetail {
                        display: flex;
                        justify-content: space-between;
                        font-size: 10px;

                        .infoItem_title {
                            font-weight: 600;
                        }

                        .infoItem_content {
                            flex: 2;
                            color: $greyishBrown;
                            max-height: 80px;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .total {
                        font-size: 12px;
                        // line-height: 15px;
                        height: 15px;
                        margin: 11px 0 15px;

                        .total_svg {
                            width: 15px;
                            height: 15px;
                            color: $vermillion;
                            margin-right: 2px;
                        }

                        span {
                            margin-right: 6px;
                        }
                    }

                    .btnBox {
                        display: flex;

                        .btn {
                            width: 62px;
                            height: 20px;
                            line-height: 20px;
                            text-align: center;
                            color: $white1;
                            font-size: 10px;
                            border-radius: 4px;
                            background-image: linear-gradient(180deg, $tomato 0, $macaroniAndCheese 100%);
                            margin-right: 20px;
                            box-shadow: 0 1px 2px $dustyOrange;
                            font-weight: 600;
                            margin-bottom: 4px;
                        }
                    }

                }
            }
        }
    }
</style>